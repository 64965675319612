<template>
    <v-container fluid>
        <v-list-item three-line>
            <v-list-item-content>
                <p class="display-1 text--primary">
                    {{$t('storageType.label')}}
                </p>
            </v-list-item-content>
        </v-list-item>
        <v-data-table :headers="headers"
                      :items="allStoragesTypes"
                      class=" agTable"
                      :loading="loading"
                      :no-data-text="$t('common.ui.not_data_found')"
                      :loading-text="$t('common.notification.loadData')"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-dialog persistent v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on }">
                            <v-btn tile outlined color="primary" class="mb-2"
                                   v-if="check([{domain: 'Record', permisions: ['Write']}])" v-on="on">
                                <v-icon color="primary">mdi-plus</v-icon>
                                {{$t('common.buttons.new')}}
                            </v-btn>
                        </template>
                        <v-card :light="true" color="white">
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="form" v-model="valid">
                                        <v-row dense>
                                            <v-col cols="2" class="mt-1">
                                                <v-icon size="50">{{editedItem.icon}}</v-icon>
                                            </v-col>
                                            <v-col cols="10"
                                                   class="d-flex justify-end">
                                                <v-switch v-model="editedItem.isFarm" color="primary">
                                                    <template v-slot:prepend>
                                                        <v-label ref="label">{{$t('storageType.fields.type')}}
                                                        </v-label>
                                                    </template>
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceStorageTypeName" v-on:keyup.ctrl.86="replaceStorageTypeName" color="primary" v-model="editedItem.storage_type_name"
                                                              :label="$t('storageType.fields.name')" required
                                                              class="required" :rules="requireAndMaxRules(max)"
                                                              :counter="max" maxlength="max"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-autocomplete :no-data-text="$t('common.fields.noDataAvailable')" small color="primary"
                                                                :label="$t('storageType.fields.company')" multiple
                                                                clearable item-color="primary" item-text="name"
                                                                :items="allCompanies" return-object
                                                                v-model="editedItem.companies" small-chips
                                                                :loading="loadingCompanies">
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" class="mt-4">
                                                <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceFilterIcon" v-on:keyup.ctrl.86="replaceFilterIcon" hide-details color="primary" shaped filled dense
                                                              v-model="filterIcon"
                                                              :label="$t('storageType.fields.search')" class="mb-3"
                                                ></v-text-field>
                                                <v-card elevation="0" class="overflow-y-auto"
                                                        height="200px">
                                                    <v-icon @click="selectIcon(icon.name)" class="mx-3 my-3"
                                                            v-for="icon in filterMaterialIcons" :key="icon.name">{{'mdi-'+icon.name}}
                                                    </v-icon>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn tile outlined color="gray" @click="close">
                                    {{$t('common.buttons.cancel')}}
                                </v-btn>
                                <v-btn tile outlined color="primary" :disabled="!valid" @click="save">
                                    {{$t('common.buttons.save')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItemDialog(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.company_name_key="{ item }">
                <v-chip v-for="company in item.company_name_key" :key="company.id" small>{{ company }}</v-chip>
            </template>
            <template v-slot:item.icon="{ item }">
                <v-icon size="30" class="ml-6">
                    {{item.icon}}
                </v-icon>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="showDialog" persistent width="600px">
                <v-card>
                    <v-card-title class="headline">{{title}}</v-card-title>
                    <v-card-text>{{dialogText}}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="neuter" @click="dialogClose">
                            {{$t('common.buttons.cancel')}}
                        </v-btn>
                        <v-btn text color="primary" @click="deleteItem">
                            {{$t('common.buttons.confirm')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>

<script>
    import i18n from '@/plugins/i18n';
    import LanguajeService from "../../../services/LanguajeService";
    import {mapActions, mapGetters} from 'vuex';
    import StorageTypesService from "../../../services/StorageTypesService";
    import PermisionService from "../../../services/PermisionService";

    export default {
        data: () => ({
            profile: null,
            valid: false,
            max: 40,
            // dialogs
            filterIcon: '',
            dialog: false,
            showDialog: false,
            title: '',
            dialogText: '',
            editedIndex: -1,
            editedItem: {
                storage_type_name: '',
                language_key: '',
                company_name_key: [],
                icon: 'mdi-ansible',
                companies: null,
                isFarm: false,
            },
            defaultItem: {
                icon: 'mdi-ansible',
                isFarm: false,
                companies: null
            },
            deletingItem: {}
        }),

        computed: {
            ...mapGetters({
                allStoragesTypes: 'adminSDT/allStoragesTypes',
                loading: 'adminSDT/loading',
                allCompanies: 'company/allCompanies',
                loadingCompanies: 'company/loading',
                requireAndMaxRules: 'general/requireAndMaxRules',
                requireRules: 'general/requireRules',
                storageTypeLocalEnum: 'adminSDT/storageTypeLocalEnum',
                material_icons: 'adminSDT/material_icons',
            }),
            headers: () => [
                {text: '', value: 'icon', width: 60},
                {text: i18n.t('storageType.fields.name'), value: 'storage_type_name'},
                {text: i18n.t('storageType.fields.company'), value: 'company_name_key'},
                {text: i18n.t('common.fields.action'), value: 'action', width: 100, sortable: false, align: 'end'},
            ],
            formTitle() {
                return (this.editedIndex === -1 ? i18n.t('common.titles.new') : i18n.t('common.titles.edit')) + ' ' + i18n.t('storageType.label');
            },
            filterMaterialIcons() {
                return this.filterIcon && this.filterIcon !== '' ? _.filter(this.material_icons, (o) => {
                    return o.name.includes(this.filterIcon)
                }) : this.material_icons;
            }
        },
        created() {
            this.initialize();
        },

        methods: {
            ...mapActions({
                fetchAllStorageTypes: 'adminSDT/fetchAllStorageTypes',
                fetchAllCompanies: 'company/fetchAllCompanies',
                saveStorageTypes: 'adminSDT/saveStorageTypes',

                languajeKeys: 'general/getLanguajeKeys'
            }),
            initialize() {
                this.profile = JSON.parse(localStorage.getItem('profile'));
                this.fetchAllStorageTypes([this.$toast]);
                this.fetchAllCompanies([this.$toast]);
            },
            check(permisions) {
                return PermisionService.check(permisions);
            },
            editItem(item) {
                this.editedIndex = this.allStoragesTypes.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItemDialog(item) {
                this.deletingItem = item;
                this.dialogShow({
                    title: i18n.t('common.titles.alert'),
                    dialogText: i18n.t('common.notification.deleteItems')
                });

            },
            dialogShow(params) {
                this.title = params.title;
                this.dialogText = params.dialogText;
                this.showDialog = true;
            },
            deleteItem() {
                this.dialogClose();
                StorageTypesService.delete(this.deletingItem.id)
                    .then((resp) => {
                        this.$toast.success(this.$t('storageType.notification.deleted'), {
                            icon: 'mdi-check-circle',
                            queueable: true
                        });
                    })
                    .catch((err) => {
                        if (err.message.includes('406')) {
                            this.$toast.info(this.$t('storageType.notification.inUse'), {
                              icon: 'mdi-information-slab-circle',
                                queueable: true
                            });
                        } else {
                            this.$toast.error(this.$t('storageType.notification.deletedError'), {
                                icon: "mdi-alert-circle",
                                queueable: true
                            });
                        }

                    }).finally(() => {
                    this.fetchAllStorageTypes([this.$toast]);
                });
            },
            dialogClose() {
                this.showDialog = false;
            },
            close() {
                this.dialog = false;
                this.$refs.form.reset();
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            },

            save() {
                let item = Object.assign({}, this.editedItem);
                item.language_key = item.id ? item.language_key : item.storage_type_name.replace(/ /g, "_").toLowerCase();
                item.languageKeyMap = {
                    language_key: {
                        id: item.id ? item.id : -1,
                        language_key: item.language_key,
                        language_label: item.storage_type_name,
                        language: LanguajeService.getLenguajeName(),
                        isProcessed: false
                    }
                };
                this.saveStorageTypes([item, this.editedIndex, this.$toast]).finally(() => {
                    this.languajeKeys([this.$toast]).finally(() => {
                        this.fetchAllStorageTypes([this.$toast]);
                    });
                });
                this.close();
            },
            selectIcon(iconName) {
                this.editedItem.icon = 'mdi-' + iconName;
            },
            replace(event){
                event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
            },
            replaceStorageTypeName(event){
               this.editedItem.storage_type_name =  event.currentTarget.value.toString().replaceAll('"','')
            },
            replaceFilterIcon(event){
                this.filterIcon =  event.currentTarget.value.toString().replaceAll('"','')
            },
        },
    }
</script>

<style scoped>

</style>
